/** Bussiness Events */
export const httpBE = () => 'be';
export const httpBESubjectActions = () => 'be/constants/subject_actions';
export const httpBEAction = (eventId: number, action: number) => `be/${eventId}/action/${action}`;
export const httpBEAccounting = () => 'be/transaction/approvals';
export const httpBETransactionPrepared = (mid: number) => `mca/${mid}/transaction/prepared`;
export const httpBETransactionGeneratePaymentsRequest = (mid: number) => `be/mca/${mid}/generatepaymentsrequest`;
export const httpBETransactionModifyPaymentScheduleRequest = (mid: number) => `be/mca/${mid}/modifypaymentschedulerequest`;
export const httpBETransactionDoublePaymentRequest = (mid: number) => `be/mca/${mid}/create_double_payment_request`;
export const httpBEApprovalAccept = () => `be/accept/batch`;
export const httpBEApprovalReject = () => `be/reject/batch`;
export const httpBETransactionsUnderwriter = () => 'be/transaction/underwriter';
export const httpBETransactionAction = (transactionId: number, action: string) => `be/transaction/${transactionId}/${action}`;
export const httpBEMcaAssignedUsers = (mid: number) => `mca/${mid}/assigment/users`;
export const httpBEMcaAction = (mid: number, action: string) => `be/mca/${mid}/${action}`;
export const httpBEMcaSubjectType = (mid: number, eventType: string) => `be/mca/${mid}/events/${eventType}`;
export const httpBEAllEvents = (eventType: string) => `be/events/${eventType}`;
export const httpBEResponse = (mid: number, parentId: number) => `mca/${mid}/assignment/unassignuser/${parentId}`;
export const httpBEResponseRole = (mid: number, parentId: number) => `mca/${mid}/assignment/unassign_role/${parentId}`;
export const httpBEReactivateIncrementRequest = (id: number) => `be/mca/${id}/reactivate_increment_request`;
export const httpBEReactivateRequests = () => `pm/reactivate_increments`;
export const httpBEReactivateRequestsAccept = () => `pm/reactivate_increments/accept/batch`;
export const httpBEReactivateRequestsReject = () => `pm/reactivate_increments/reject/batch`;

export const httpBEMcaRateChangeRequests = () => `pm/mcarate`;
export const httpBEMcaRateChangeRequestAccept = () => `pm/mcarate/accept/batch`;
export const httpBEMcaRateChangeRequestReject = () => `pm/mcarate/reject/batch`;
export const httpBEMcaRateChangeRequestComment = () => `pm/mcarate/comment`;

export const httpBEMcaBankAccountRequests = () => `pm/bankaccount`;
export const httpBEMcaBankAccountRequestAccept = (parentId: number) => `pm/bankaccount/${parentId}/accept`;
export const httpBEMcaBankAccountRequestReject = (parentId: number) => `pm/bankaccount/${parentId}/reject`;

export const httpBEMcaContraCFRequests = () => `pm/events/contra_cf`;
export const httpBEMcaContraCFRequestAccept = (parentId: number) => `pm/${parentId}/contra_cf/accept`;
export const httpBEMcaContraCFRequestReject = (parentId: number) => `pm/${parentId}/contra_cf/reject`;
export const httpBEMcaContraCFRequest = (mcaid: number) => `be/mca/${mcaid}/events/contra_cf`;

/** Refund */
export const httpRefund = () => `be/refund`;
export const httpRefundReject = (parentEventId: number) => `be/refund/${parentEventId}/reject`;
export const httpRefundAccept = (parentEventId: number) => `be/refund/${parentEventId}/accept`;
export const httpRefundRequest = () => `be/refund/refundrequest`;
export const httpRefundRequestReject = (parentEventId: number) => `be/refund/refundrequest/${parentEventId}/reject`;
export const httpRefundRequestAccept = (parentEventId: number) => `be/refund/refundrequest/${parentEventId}/accept`;
export const httpRefundCheckActiveRequests = (mcaid: number) => `be/refund/${mcaid}`;
export const httpRefundCheckPendingRequests = (mcaid: number) => `be/refund/${mcaid}/events`;

export const httpVenues = () => 'general/venues';

/** UI Grid Config */
export const httpGridConfig = (presetId?: string) => (presetId ? `user/grid/${presetId}` : 'user/grid');

/** Assignments */
export const httpBEMcaAssignToUser = (mid: number, uid: number | string) => `mca/${mid}/assignment/assignuser/${uid}`;
export const httpBEMcaAssignToRole = (mid: number, rid: number) => `mca/${mid}/assignment/assignrole/${rid}`;
export const httpBEAssignUsersByEin = (ein: string) => `ein/${ein}/assignment/assignusers`;
export const httpBEUnassignUsersByEin = (ein: string) => `ein/${ein}/assignment/unassignusers`;
