import { Injectable, inject } from '@angular/core';
import {
  httpBEAllEvents,
  httpBEMcaAction,
  httpBEMcaAssignedUsers,
  httpBEMcaAssignToRole,
  httpBEMcaAssignToUser,
  httpBEMcaRateChangeRequestAccept,
  httpBEMcaRateChangeRequestReject,
  httpBEMcaRateChangeRequests,
  httpBEMcaSubjectType,
  httpBEResponse,
  httpBETransactionDoublePaymentRequest,
  httpBETransactionGeneratePaymentsRequest,
  httpBETransactionModifyPaymentScheduleRequest,
  httpBEReactivateIncrementRequest,
  httpRefund,
  httpRefundAccept,
  httpRefundCheckActiveRequests,
  httpRefundCheckPendingRequests,
  httpRefundReject,
  httpRefundRequest,
  httpRefundRequestAccept,
  httpRefundRequestReject,
  httpBEReactivateRequests,
  httpBEReactivateRequestsAccept,
  httpBEReactivateRequestsReject,
  httpBEMcaBankAccountRequests,
  httpBEMcaBankAccountRequestAccept,
  httpBEMcaBankAccountRequestReject,
  httpBEResponseRole,
  httpBEMcaContraCFRequestAccept,
  httpBEMcaContraCFRequestReject,
  httpBEMcaContraCFRequests,
  httpBEMcaContraCFRequest,
  httpBESubjectActions,
  httpBE,
  httpBEAssignUsersByEin,
  httpBEUnassignUsersByEin,
} from './shared-http-points';
import { ApiService } from '@mca/shared/util';
import { Observable } from 'rxjs';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import { BusinessEventsComponentFilter } from '../entities/businesseventfilter';
import { UserAssignmentEvent } from '../entities/user-assignment';

export interface PendingRequests {
  create_ts: Date;
  amount: number;
  total_amount: number;
  status: string;
  stage: string;
  note: string;
  id: number;
}

export interface ReactivateIncrementRequestAction {
  id: number;
  create_ts: Date;
  create_user: number;
  note: string;
  mcaid: number;
  ids: number[];
  effectiveDate: string;
  dbaName: string;
  batch_id: number;
}

export interface ContraCFRequestAction {
  action: string;
  amount: number;
  batch_id: number;
  create_ts: string;
  create_user: number;
  dbaName: string;
  effectiveDate: string;
  event: string;
  id: number;
  mcaid: number;
  note: string;
}

@Injectable({
  providedIn: 'root',
})
export class BusinessEventService {
  private apiService = inject(ApiService);

  getBE(fltr: BusinessEventsComponentFilter) {
    return this.apiService.get(httpBE(), { params: this.filter2param(fltr) });
  }

  list(fltr: BusinessEventsComponentFilter) {
    return this.apiService.get(httpBEMcaSubjectType(fltr.subjectId, fltr.actionSubject), { params: this.filter2param(fltr) });
  }

  getAllEvents(actionSubject: string, fltr: BusinessEventsComponentFilter) {
    return this.apiService.get(httpBEAllEvents(actionSubject), { params: this.filter2param(fltr) });
  }

  mcaAssignToUser(
    mcaId: number,
    userId: number | string,
    body: {
      note: string;
      user_type?: string;
    },
  ) {
    return this.apiService.post(httpBEMcaAssignToUser(mcaId, userId), body);
  }

  mcaAssignToRole(
    mcaId: number,
    roleId: number,
    body: {
      note: string;
    },
  ) {
    return this.apiService.post(httpBEMcaAssignToRole(mcaId, roleId), body);
  }

  mcaAssignUsersByEin(
    ein: string,
    body: {
      note: string;
      users: {
        userid: number;
        user_type: string;
      }[];
    },
  ) {
    return this.apiService.post(httpBEAssignUsersByEin(ein), body);
  }

  mcaUnassignUsersByEin(
    ein: string,
    body: {
      user_types: any[];
    },
  ) {
    return this.apiService.post(httpBEUnassignUsersByEin(ein), body);
  }

  getAssignedUsers(
    mcaId: number,
    params: {
      roles?: number[];
    },
  ) {
    return this.apiService.get<UserAssignmentEvent[]>(httpBEMcaAssignedUsers(mcaId), { params });
  }

  respondToEvent(
    mcaId: number,
    parentEventId: number,
    body: {
      action: number;
      note?: string;
      subject_action?: {
        result?: string;
        is_declined?: boolean;
      };
    },
  ) {
    return this.apiService.post(httpBEResponse(mcaId, parentEventId), body);
  }

  respondToRoleEvent(
    mcaId: number,
    parentEventId: number,
    body: {
      action: number;
      note?: string;
      subject_action?: {
        result?: string;
        is_declined?: boolean;
      };
    },
  ) {
    return this.apiService.put(httpBEResponseRole(mcaId, parentEventId), body);
  }

  postAction(id: number, action: string, body: any): Observable<any> {
    return this.apiService.post(httpBEMcaAction(id, action), body);
  }

  generatePaymentScheduleRequest(mcaid: number, requestInfo: any): Observable<any> {
    return this.apiService.post(httpBETransactionGeneratePaymentsRequest(mcaid), requestInfo);
  }
  requestModifyPaymentSchedule(mcaid: number, requestInfo: any): Observable<any> {
    return this.apiService.post(httpBETransactionModifyPaymentScheduleRequest(mcaid), requestInfo);
  }

  requestDoublePayment(mcaid: number, requestInfo: any): Observable<any> {
    return this.apiService.post(httpBETransactionDoublePaymentRequest(mcaid), requestInfo);
  }

  sendRefundRequest(
    mcaid: number,
    data: {
      amount: number;
      comment: string;
    },
  ): Observable<any> {
    return this.apiService.post(httpRefundRequest(), {
      mcaid,
      ...data,
    });
  }

  getRefund(params: { startDate: string; endDate: string }): Observable<any> {
    return this.apiService.get(httpRefund(), { params });
  }

  acceptRefund(
    parentEventId: number,
    data: {
      effectiveDate: string;
      comment: string;
      transfer_to: {
        mcaid: number;
        amount: number;
      }[];
    },
  ) {
    return this.apiService.post(httpRefundAccept(parentEventId), data);
  }

  rejectRefund(
    parentEventId: number,
    data: {
      comment: string;
    },
  ) {
    return this.apiService.post(httpRefundReject(parentEventId), data);
  }

  getRefundRequest(params: { startDate: string; endDate: string }): Observable<any> {
    return this.apiService.get(httpRefundRequest(), { params });
  }

  acceptRefundRequest(
    parentEventId: number,
    data: {
      amount: string;
      comment: string;
    },
  ) {
    return this.apiService.post(httpRefundRequestAccept(parentEventId), data);
  }

  rejectRefundRequest(
    parentEventId: number,
    data: {
      comment: string;
    },
  ) {
    return this.apiService.post(httpRefundRequestReject(parentEventId), data);
  }

  checkActiveRefundRequests(mcaId: number): Observable<any> {
    return this.apiService.get(httpRefundCheckActiveRequests(mcaId));
  }

  checkPendingRequests(mcaId: number) {
    return this.apiService.get<PendingRequests[]>(httpRefundCheckPendingRequests(mcaId));
  }

  getRateChangeRequestList(params: { startDate: string; endDate: string }): Observable<any> {
    return this.apiService.get(httpBEMcaRateChangeRequests(), { params });
  }

  acceptRateChangeRequest(data: { comment: string; eventIds: number[] }) {
    return this.apiService.post(httpBEMcaRateChangeRequestAccept(), data);
  }

  rejectRateChangeRequest(data: { comment: string; eventIds: number[] }) {
    return this.apiService.post(httpBEMcaRateChangeRequestReject(), data);
  }

  getRateBankAccountRequestList(params: { startDate: string; endDate: string }) {
    return this.apiService.get(httpBEMcaBankAccountRequests(), { params });
  }

  acceptBankAccountRequest(
    parentEventId: number,
    data: {
      comment: string;
    },
  ) {
    return this.apiService.post(httpBEMcaBankAccountRequestAccept(parentEventId), data);
  }

  rejectBankAccountRequest(
    parentEventId: number,
    data: {
      comment: string;
    },
  ) {
    return this.apiService.post(httpBEMcaBankAccountRequestReject(parentEventId), data);
  }

  createMcaContraCFRequest(
    mcaId: number,
    data: {
      effectiveDate: string;
      amount: number;
      note: string;
    },
  ) {
    return this.apiService.post(httpBEMcaContraCFRequest(mcaId), data);
  }

  getContraCFRequestList(params: { startDate: string; endDate: string }) {
    return this.apiService.get<ContraCFRequestAction[]>(httpBEMcaContraCFRequests(), { params });
  }

  acceptContraCFRequest(
    parentEventId: number,
    data: {
      note: string;
    },
  ) {
    return this.apiService.put(httpBEMcaContraCFRequestAccept(parentEventId), data);
  }

  rejectContraCFRequest(
    parentEventId: number,
    body: {
      note: string;
    },
  ) {
    return this.apiService.delete(httpBEMcaContraCFRequestReject(parentEventId), { body });
  }

  requestReactivateIncrements(
    mcaid: number,
    params: {
      ids: number[];
      effectiveDate: string;
      note: string;
    },
  ) {
    return this.apiService.post(httpBEReactivateIncrementRequest(mcaid), params);
  }

  getReactivateRequests(params: { startDate: string; endDate: string }) {
    return this.apiService.get<ReactivateIncrementRequestAction[]>(httpBEReactivateRequests(), { params });
  }

  acceptReactivateIncrementsRequest(data: { note: string; eventIds: number[] }) {
    return this.apiService.post(httpBEReactivateRequestsAccept(), data);
  }

  rejectReactivateIncrementsRequest(data: { note: string; eventIds: number[] }) {
    return this.apiService.post(httpBEReactivateRequestsReject(), data);
  }

  filter2param(fltr: BusinessEventsComponentFilter): any {
    const params: any = {};
    if (fltr.action && fltr.action.length > 0) {
      params['action'] = fltr.action;
    }
    if (fltr.assigneeType && fltr.assigneeType !== 0) {
      params['assingneeType'] = fltr.assigneeType;
    }
    if (fltr.assigneeId) {
      params['assingneeId'] = fltr.assigneeId;
    }
    if (fltr.subjectType && fltr.subjectType.length > 0) {
      params['subjectType'] = fltr.subjectType;
    }
    if (fltr.from) {
      params['startDate'] = dateAsYYYYMMDD(fltr.from);
    }
    if (fltr.to) {
      const endDate = new Date();
      endDate.setDate(fltr.to.getDate() + 1);
      params['endDate'] = dateAsYYYYMMDD(endDate);
    }
    if (fltr.status && fltr.status.length > 0) {
      params['status'] = fltr.status;
    }
    if (fltr.paymentStatus && fltr.paymentStatus.length > 0) {
      params['paymentStatus'] = fltr.paymentStatus;
    }
    if (fltr.subjectId && fltr.subjectId !== 0) {
      params['subjectId'] = fltr.subjectId;
    }
    if (fltr.assignedOnly) {
      params['assignedOnly'] = fltr.assignedOnly;
    }
    if (fltr.latestMcaEvent) {
      params['latestMcaEvent'] = fltr.latestMcaEvent;
    }
    if (fltr.roles) {
      params['roles'] = fltr.roles;
    }
    if (fltr.subjectAction) {
      params['subjectActionAction'] = fltr.subjectAction;
    }
    if (fltr.id) {
      params['id'] = fltr.id;
    }

    return params;
  }

  getBESubjectActions() {
    return this.apiService.get<string[]>(httpBESubjectActions());
  }
}
